import requests from './httpService';

const SchoolService = {
  getAllSchool(id) {
    return requests.get(`/registration/list/${id}`);
  },
  getAllSchoolList() {
    return requests.post(`/registration/find`,{});
  },
  upadeSchool(body) {
    return requests.put(`/registration/${body._id}`,body); 
  },
 
  getAllStudentById(id,body){
    return requests.post(`/student/list/${id}`,body); 
  },
  deleteStudent(id){
    return requests.delete(`/student/${id}`); 
  },
  upadeStudent(body) {
    return requests.put(`/student/${body._id}`,body); 
  },
  
 
};

export default SchoolService;
