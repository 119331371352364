import React, { useState } from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
  TableRow, Table,
  TableHead,
  TableBody,
  TableCell,
  Checkbox, FormControlLabel,
} from "@material-ui/core";
import AddClassService from "./Locality/Service/addClassService";

import { Card, Box } from "@material-ui/core";
import StudentService from "./Locality/Service/studentService"
import EventService from "./Locality/Service/eventService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#30875b",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
export default function Event() {
  const tableHeaders = [ 'Event Name ',  'classId', 'Date', 'Discription',  'Delete'];
  const [images, setImages] = useState('');
  const [imageslist, setImagesList] = useState('');
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [studentList, setStudentList] = useState([]);
  const [addClassList, setAddClassList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [startDate, setFormStartDate] = useState("");
  const [age, setAge] = React.useState('');
  var [error, setError] = useState(null);
  var [uploadError, setUploadError] = useState({ isShowError: false, successCount: 0, error: [], message: "" });
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(5);
  const current = new Date();
  var [classId, setClassId] = useState("");
  const date = `${current.getFullYear()}-0${current.getMonth() + 1}-${current.getDate()}`;
  var [dateValue, setDateValue] = useState(date);
  const [eventId, setEventId] = useState('');

  const [event, setEvent] = useState({
    eventName: '',
    imageURL: '',
    discription: '',
    video: '',
    classId: '',
    date: '',
    isEventForAllStudent: false,
    startDate: ''
  });

  const validationSchema = Yup.object().shape({
    eventName: Yup.string().required('event Name is required'),
    imageURL: Yup.string(),
    video: Yup.string(),
    classId: Yup.string().required('Class Name is required'),
    discription: Yup.string(),
    date: Yup.string(),
    isEventForAllStudent: Yup.bool()
  });
  const handleChangePage = (event, newpage) => {
    setpg(newpage);
  }
  const handleChangeRowsPerPage = (event) => {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  useEffect(() => {
    getEventList();

    getAddClassList();
    return () => {
      setEventList([]);
      setStudentList([]);
    }
  }, []);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormStartDate(value);
  };
  const handleFileChange = (e) => {
    setImages(e.target.files); // Store all selected files in state
  };
  const handleUpload = async (e) => {
    e.preventDefault();

    if (images.length === 0) {
      setMessage("Please select images first.");
      return;
    }

    // Create FormData and append each file
    const formData = new FormData();
    if (images.length > 0) {
      const image = images[0];
      formData.append(`image`, image);

      formData.append(`imageName`, image.name);
      // images.forEach((image, index) => {
      //   formData.append(`images`, image); // Append each file to the form
      // });

      try {
        // Send the images to the API
        EventService.uploadImage(formData).then((res) => {

          alert(`Images uploaded successfully`);
          setImagesList((prev) => [...prev, res.url]);


        }).catch((err) => {
          alert(err.message);
        });

        //   const response = await fetch("http://localhost:3000/upload-multiple", {
        //     method: "POST",
        //     body: formData,
        //   });

        //   if (response.ok) {
        //     const result = await response.json();
        //     setMessage(`Images uploaded successfully: ${result.fileUrls.join(", ")}`);
        //   } else {
        //     setMessage("Failed to upload images.");
        //   }
      } catch (error) {
        console.error("Error uploading images:", error);
        setMessage("An error occurred while uploading the images.");
      }
    }

  };
  const getEventList = () => {
    EventService.getAllEvent().then((res) => {
      setEventList(res);
    }).catch((err) => {
      setError(err.message);
    });
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const getAddClassList = (event) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {
      if (userDetails.role === "TEACHER") {
        res = res.filter(res => res._id === userDetails.classId);
      }
      setAddClassList(res);
    }).catch((err) => {
      // setError(err.message);
    });
  }
  const editEvent = (event, status) => {
    const obj = JSON.parse(JSON.stringify(event, status));
    obj.classId = event.classId ? event.classId._id : '';
    obj.studentId = event.studentId ? event.studentId._id : '';

    getStudentList({ target: { value: obj.classId } }, obj);

    getAddClassList({ target: { value: obj.superActivityId } }, obj)
    //getStudentActivityList(useractivitys.classId);
    if (status) {
      delete obj._id;
    }
    setEvent(obj);
    handleOpen()
  }

  const deleteEvent = (eventdelete) => {
    if (eventdelete) {
      EventService.deleteEvent(eventdelete).then((res) => {

        getEventList()
      }).catch((err) => {
      });
    }
  }


  const getStudentList = (event, obj) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    const getSpeList = { classId: event.target.value };
    StudentService.getAllStudentById(userDetails.schooleId,
      getSpeList
    ).then((res) => {
      const studentDetails = res.map(res => {

        return { _id: res._id, studentName: `${res.firstName} ${res.lastName}`, status: true };
      })
      setStudentList(studentDetails);

    }).catch((err) => {
      // setError(err.message);
    });
  }

  const formik = useFormik({
    initialValues: event,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const userDetails = JSON.parse(localStorage.getItem("userDetail"));
      values.schooleId = userDetails.schooleId;
      values.date = startDate;
      if (event._id) {
        EventService.upadeEvent(values).then((res) => {
          handleClose();
          getEventList();
          resetForm()
          setImagesList([])
          alert(" Event Updated Successfully.");
        }).catch((err) => {
        });
      }
      else {
        values.imageURL = imageslist;
       

        EventService.creteEvent(values).then((res) => {
          getEventList();
          resetForm();
          handleClose();
          alert(" Event Added Successfully.");
          setImagesList([])
        })
          .catch((err) => {
            alert(err.response.data.message)
          })
      }

    },
  });
  const handleCheck = (event, item) => {
    // event.sounds.SPL
    event.sounds[item] = !event.sounds[item];
    // obj['a'] = true;

    StudentService.upadeStudent(event).then((res) => {
      getStudentList();

    })
      .catch((err) => {
        alert(err.response.data.message)
      })

  };
  const imageArtist = (eventdetails) => {
    getEventImageList(eventdetails.id);
    setEventId(eventdetails.id)

  }
  const getEventImageList = (eventId) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    // CommonService.postRequest(IMAGES.GET+"/getlist", {artiesId:eventId}).then((res) => {
    //     setEventImageList(res);
    const keys = {
      "schooleId": userDetails.schooleId, "studentList": studentList, "teacherId": userDetails._id,
      "date": dateValue, "eventId": userDetails.eventId
    }
    EventService.creteImages(keys).then((res) => {
      setDateValue("");
      setStudentList([]);
      alert(" Event Successfully.");
    }).catch((err) => {

    });
  }
  const onSubmit = data => {
    const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    const keys = {
      "schooleId": userDetails.schooleId, "studentList": studentList, "teacherId": userDetails._id,
      "date": dateValue
    }
    EventService.creteEvent(keys).then((res) => {
      debugger
      setDateValue("");
      setStudentList([]);
      alert(" Event Successfully.");
    }).catch((err) => {

      alert(err.response.data.message);
    });
  };
  return (

    <>
      <PageTitle title="Assignment" button={<Button

        variant="contained" onClick={() => handleOpen(true)}
        size="medium"
        color="secondary" style={{ backgroundColor: '#30875b' }}> Add Assignment
      </Button>} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
            <Table className="mb-0">
              <TableHead >
                <TableRow>
                  {tableHeaders.map(key => (
                    <StyledTableCell key={key}>{key}</StyledTableCell>
                  ))}
                </TableRow>

              </TableHead>
              <TableBody>
                {eventList.map((event) => (
                  <TableRow key={event._id}>

                    {/* <TableCell className="pl-3 fw-normal" >
                      {event.studentId ? event.studentId.firstName : ""}&nbsp;
                      {event.studentId ? event.studentId.lastName : ""}
                    </TableCell> */}
                    <TableCell className="pl-3 fw-normal" >{event.eventName}</TableCell>
                    {/* <TableCell className="pl-3 fw-normal" >{event.imageURL}</TableCell>
                    <TableCell className="pl-3 fw-normal" >{event.video}</TableCell> */}
                    <TableCell className="pl-3 fw-normal" >{event.classId ? event.classId.className : ''}</TableCell>
                    <TableCell className="pl-3 fw-normal" >{event.date}</TableCell>
                    <TableCell className="pl-3 fw-normal" >{event.discription}</TableCell>
                    {/* <TableCell>
                      <EditIcon style={{ cursor: 'pointer' }} onClick={() => editEvent(event)} >
                      </EditIcon >
                    </TableCell> */}
                    <TableCell>
                      <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteEvent(event)} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Widget>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Assignment</DialogTitle>
        <form onSubmit={formik.handleSubmit} >
          <DialogContent style={{ width: 600 }}>
            <FormControl className={classes.formControl} fullWidth="true"
            >
              <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="classId"
                label="selectClass"
                // value={formik.values.classId}
                // value={classId}
                value={formik.values.classId}
                onChange={e => { formik.handleChange(e); getStudentList(e) }}
                // onChange={e => { setClassId(e.target.value); getStudentList(e);  }}
                // onChange={formik.handleChange}

                error={formik.touched.classId && Boolean(formik.errors.classId)}
                helperText={formik.touched.classId && formik.errors.classId}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {addClassList.map(({ _id, className }) => (
                  <MenuItem key={_id} value={_id}>{className}

                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="studentName">Student Name</InputLabel>
              <Select

                labelId="studentName"
                id="studentName"
                label="Student Name"
                name="studentId"
                value={formik.values.studentId}

                onChange={formik.handleChange}
                error={formik.touched.studentId && Boolean(formik.errors.studentId)}
                helperText={formik.touched.studentId && formik.errors.studentId}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {studentList.map(({ index, _id, studentName }) => (
                  <MenuItem key={index} value={_id}>{studentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br></br>
            <br></br>
          
            <Grid xs={12} md={12}>
              <TextField
                // disabled={formData.id != ""}
                type="date"
                style={{ width: "100%" }}
                name="startDate"
                placeholder="DateRange"
                value={startDate}
                // isInvalid={!!formErrors.startDate}

                onChange={handleChange}
              />
            </Grid>

            <TextField InputProps={{ style: { width: 550 } }}
              margin="dense"
              id="eventName"
              name="eventName"
              label="Event Name"
              type="text"
              variant="standard"
              value={formik.values.eventName}
              onChange={formik.handleChange}
              error={formik.touched.eventName && Boolean(formik.errors.eventName)}
              helperText={formik.touched.eventName && formik.errors.eventName}
            />
            <TextField InputProps={{ style: { width: 550 } }}
              margin="dense"
              id="discription"
              name="discription"
              label="Discription"
              type="text"
              variant="standard"
              value={formik.values.discription}
              onChange={formik.handleChange}
              error={formik.touched.discription && Boolean(formik.errors.discription)}
              helperText={formik.touched.discription && formik.errors.discription}
            />
            <TextField InputProps={{ style: { width: 550 } }}
              margin="dense"
              id="video"
              name="video"
              label="Video Link"
              type="text"
              variant="standard"
              value={formik.values.video}
              onChange={formik.handleChange}
              error={formik.touched.video && Boolean(formik.errors.video)}
              helperText={formik.touched.video && formik.errors.video}
            />
            <div>
              <Grid item xs={12} sm={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox name="isEventForAllStudent" id="terms"
                      value={formik.values.isEventForAllStudent}
                      onChange={formik.handleChange}
                      error={formik.touched.isEventForAllStudent && Boolean(formik.errors.isEventForAllStudent)}
                      helperText={formik.touched.isEventForAllStudent && formik.errors.isEventForAllStudent}
                    />
                  }
                  label="For All Class Students"
                />
              </Grid>

            </div>
            <br></br>

            <input
              type="file"
              accept="image/*"
              multiple // Enable multiple file selection
              onChange={handleFileChange}
            />
            <Button style={{ backgroundColor: 'rgb(51 129 90)',color:'white', border: 'none' }} onClick={handleUpload}>Upload</Button>

            {imageslist.length > 0 && (
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {imageslist.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={`Uploaded ${index + 1}`}
                    style={{ width: "100px", height: "100px", objectFit: "cover" }}
                  />
                ))}
              </div>
            )}

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" >Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}


