import requests from './httpService';
const EventService = {
  getAllEvent() {
    return requests.get(`/event/list`);
  },
  getEventbyId(id) {
    return requests.get(`/event/${id}`);
  },
  uploadImage(body) {
    return requests.post(`/event/uploadimage`,body);
  },
  creteImages(body){
    return requests.post('/image/add',body);
  },
  creteEvent(body){
    return requests.post('/event/add',body); 
  },
  deleteEvent(body){
    return requests.delete(`/event/${body._id}`); 
  },
  findEventList(body){
    return requests.post(`/event/find`,body); 
  },
  upadeEvent(body) {
    return requests.put(`/event/${body._id}`,body); 
  },

};
export default EventService;
