import React, { useState } from "react";
import {
  LinearProgress,
  OutlinedInput,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem,
  TableRow, Table,
  TableHead,
  TableBody,
  TableCell, Checkbox
} from "@material-ui/core";
// import Checkbox from '@material-ui/Checkbox';
import schooleRegistration from "./Locality/Service/schooleRegistration"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import { Grid, Card, Box, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';

import { useContext, useEffect } from 'react';
import { useTheme } from "@material-ui/styles";

import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import { withStyles } from '@material-ui/core/styles';
import SchooleRegistrationService from "./Locality/Service/schooleRegistration";
export default function Privacy(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  var [error, setError] = useState(null);
  const [schooleRegistrationList, setSchooleRegistrationList] = useState([]);
  const [schooleRegistration, setSchooleRegistration] = useState({
    schooleName: '',
    address: '',
    city: '',
    pincode: '',
    selectCountry: '',
    name: '',
    email: '',
    mobileNumber: '',
    schooleEmail: '',
    schooleContact: '',
    password: '',
    subscription: '',
    terms: '',
  });

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const validationSchema = Yup.object().shape({
    schooleName: Yup.string().required('schoole Name  is required'),
     address: Yup.string().required('address is required'),
    city: Yup.string().required('city is required'),
    pincode: Yup.string().required('pin code is required')
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits'),
    selectCountry: Yup.string().required('selectCountry is required'),
    name: Yup.string().required('name is required'),
    email: Yup.string(),
    mobileNumber: Yup.string().required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),
    schooleEmail: Yup.string()
      .required('schoole Email is required'),
    schooleContact: Yup.string().required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Must be exactly 10 digits')
      .max(10, 'Must be exactly 10 digits'),
    password: Yup.string().required('password is required'),
     subscription: Yup.string(),
     terms: Yup.string().required('Terms and Conditions is required'),
  });
  const getSchooleRegistrationList = () => {
    SchooleRegistrationService.getAllSchooleRegistration().then((res) => {
      setSchooleRegistrationList(res);
    }).catch((err) => {
      setError(err.message);
    });
  }
  // onSubmitHandler = (e) => {
  //   e.preventDefault();
  //  this.props.history.push('/login')
  // }
  const formik = useFormik({
    initialValues:  schooleRegistration,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      SchooleRegistrationService.creteSchooleRegistration(values).then((res) => {
 
        alert(" Registration Successfully.");
        props.history.push('/montessori/login');
      })
        .catch((err) => {
          alert(err.response.data.message);
        })
    },
  });


  return (

    <>
      <Card sx={{ maxWidth: 345 }}>
        <Box   >
          <div style={{ marginLeft: "7%" }}>
              <Grid container spacing={2} columns={12} >
                <Grid item xs={12}>
                  <PageTitle InputProps={{ style: { color: '#10b680' } }} title=" Privacy policy" ></PageTitle>
                </Grid>
                <h3>Privacy Policy for Montessori School Activity Monitor Software, "montestory.in" </h3>
              </Grid>
              
              <h3>1. Introduction</h3>
            <p>Srshta Tech Solutions Pvt ltd, we are committed to protecting the privacy of users who interact with our Montessori School Activity Monitor Software ("www.montestory.in"). This Privacy Policy outlines our practices regarding the collection, use, disclosure, and protection of personal information collected through the Software. By using the Software, you agree to the practices described in this Privacy Policy.</p>
                <h3>2. Information We Collect</h3>
                <p>We may collect the following types of information when the Software is used:</p>
                <h4>a. Personal Information</h4>
               <p><b>•	Student Information:</b> Name, age, attendance records, activity progress, assessment results, and any other educational data entered by the school.</p> 
               <p><b>•	Staff Information:</b> Names, email addresses, usernames, roles within the school, and other relevant information provided by the School.</p>
               <p><b>•	Parent/Guardian Information (if applicable):</b> Contact details such as email addresses for communication purposes.</p>
               <h4>b. Usage Data</h4>
               <p>•	Data about how the Software is accessed and used, including activity logs, session durations, user interactions, and device information (e.g., IP address, browser type, operating system).</p>
               <h3>3. How We Use the Information</h3>
               <p>We use the collected information to:</p>
               <p>•	Facilitate the educational processes within the Montessori environment.</p>
               <p>•	Provide, maintain, and improve the Software’s functionality.</p>
               <p>•	Track student progress and create activity reports.</p>
               <p>•	Communicate with authorized staff members regarding updates, support, or other relevant information.</p>
               <p>•	Ensure the security and integrity of the Software.</p>
               <p>•	Comply with legal obligations or enforce applicable terms and conditions.</p>
               <h3>4. Legal Basis for Data Processing</h3>
               <p>We process personal information based on the following legal grounds:</p>
               <p><b>•	Consent: </b>We may rely on consent provided by parents/guardians or staff for specific uses of personal data.</p>
               <p><b>•	Legitimate Interests: </b>Data may be processed for legitimate educational interests, including enhancing learning outcomes.</p>
               <p><b>•	Contractual Obligations:</b> To fulfil any contractual obligations with the School regarding the Software’s use.</p>
               <p><b>•	Legal Compliance:</b> To comply with applicable laws, regulations, or legal processes.</p>
               <h3>5. Data Sharing and Disclosure</h3>
               <p>We do not sell or rent personal data to third parties. However, we may share information in the following situations:</p>
               <p><b>•	With the School:</b> Authorized school personnel have access to data to facilitate education and administration.</p>
               <p><b>•	Service Providers:</b> Third-party vendors who provide essential services to the Software (e.g., hosting, data analytics, technical support), subject to strict data protection agreements.</p>
               <p><b>•	Legal Requirements:</b> If required by law or in response to valid legal processes, including subpoenas or court orders.</p>
               <p><b>•	Business Transfers: </b>In the event of a merger, acquisition, or sale of assets, personal data may be transferred to a successor entity.</p>
               <h3>6. Data Security</h3>
               <p>We implement industry-standard security measures to protect personal data from unauthorized access, loss, misuse, or alteration. These measures include:</p>
               <p><b>•	Data Encryption:</b> Personal data is encrypted during transmission and at rest to ensure confidentiality.</p>
               <p><b>•	Access Controls:</b> Access to the Software is restricted to authorized users through unique credentials.</p>
               <p><b>•	Regular Security Audits: </b>Periodic security reviews and vulnerability assessments are conducted to ensure data protection.</p>
               <h3>6. Data Retention</h3>
               <p>We retain personal data only for as long as necessary to fulfil the purposes outlined in this Privacy Policy or as required by law. Upon request from the school, parents, or guardians, data may be deleted or anonymized, subject to any legal obligations to retain it.</p>
               <h3>8. Parental/Guardian Consent</h3>
               <p>For data involving children under 13 (or the relevant age of consent), we require parental or guardian consent before collecting, processing, or sharing any personal data. Schools are responsible for obtaining necessary consents and providing proof if requested.</p>
               <h3>9. Cookies and Tracking Technologies</h3>
               <p>The Software may use cookies or similar technologies to:</p>
               <p>•	Improve user experience by remembering preferences and settings.</p>
               <p>•	Analyze usage patterns for optimization purposes.</p>
               <p>•	Ensure security during user sessions.</p>
               <p>Users can control cookie settings through their browser preferences. Disabling cookies may affect certain functionalities of the Software.</p>
               <h3>10. Your Data Protection Rights</h3>
               <p>Depending on your location, you may have rights related to your personal data, including:</p>
               <p><b>•	Access:</b> The right to request copies of personal data.</p>
               <p><b>•	Correction:</b> The right to request corrections to inaccurate or incomplete data.</p>
               <p><b>•	Deletion: </b>The right to request deletion of personal data.</p>
               <p><b>•	Restriction:</b> The right to request restriction on processing personal data.</p>
<p><b>• Portability:</b> The right to request a copy of your data in a commonly used format.</p>
<p><b>•	Objection:</b> The right to object to data processing for certain purposes.</p>
<p>To exercise any of these rights, please contact us at [Contact Information].</p>
<h3>11. Data Transfers</h3>
<p>Personal data may be transferred to and processed in countries outside the country of origin where data protection laws may differ. We take steps to ensure that data transfers are protected by appropriate safeguards, such as standard contractual clauses or other lawful mechanisms.</p>
<h3>12. Changes to this Privacy Policy</h3>
<p>We may update this Privacy Policy periodically to reflect changes in our practices, technology, or legal obligations. Any changes will be posted within the Software, and significant updates will be communicated to the school via email.</p>
<h3>13. Contact Us</h3>
<p>For any questions or concerns about this Privacy Policy or how we handle personal information, please contact us at:</p>
<b>•	Email: support@montestory.in </b>


          </div>
        </Box>

      </Card>


    </>
  );
}


