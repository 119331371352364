import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Select,
    TableRow, Table,TableHead, TableBody,TableCell, FormControl, InputLabel, MenuItem} from "@material-ui/core";
import SuperActivityService from "./Locality/Service/superActivityService";
import ActivityTabelService from "./Locality/Service/activityTabelService";
import AddClassService from "./Locality/Service/addClassService";
import * as Yup from 'yup';
import { Grid, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import {  useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Activity() {
    const tableHeaders = [  'Class','Area Of Work','Edit', 'Delete'];
    const classes = useStyles();
    const [superActivityList, setSuperActivityList] = useState([]);
    const [age, setAge] = React.useState('');
    const [tabelIdList, setTabelIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [tabel, setTabel] = useState({
        superActivityName: '',
        classId:'',
    });
    const [addClassList, setAddClassList] = useState([]);
    const validationSchema = Yup.object().shape({
        superActivityName: Yup.string().required('Super Activity Name is required'),
    });
    useEffect(() => {
        getSuperActivityList();
        getAddClassList();
        return () => {
            setTabelIdList([]);
            setSuperActivityList([]);
        }
    }, []);
    const handleOpen = (status) => {
        if(status){
            setTabel({
                superActivityName: '',
                classId:'',
        })
        }
     
          setOpen(true);
      };
    const handleClose = () => {
        setOpen(false);
    };
    const getAddClassList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {
            setAddClassList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    const getSuperActivityList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        SuperActivityService.getAllSuperActivity(userDetails.schooleId).then((res) => {
            setSuperActivityList(res);
        }).catch((err) => {
            // setError(err.message);
        });
    }
    // const getAddClassList = () => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //     AddClassService.getAllAddClass(userDetails.schooleId).then((res) => {
    //         setAddClassList(res);
    //     }).catch((err) => {
            
    //     });
    // }
    // const getClassNameList = (event) => {
    //     AddClassService.getAddClassNameById({ className: event.target.value }).then((res) => {
          
    //         setClassNameList(res);

    //     }).catch((err) => {
    //         setError(err.message);
    //     });
    // }
    const editActivityTabel = (tabel) => {
        const obj = JSON.parse(JSON.stringify(tabel));
        obj.superActivityId = tabel.superActivityId ? tabel.superActivityId._id : '';
        obj.classId = tabel.classId ? tabel.classId._id :'';
        // obj.activityId = sub.activityId ? sub.activityId._id : '';
        setTabel(obj)
        handleOpen(false)
    }
    const deleteActivityTabel = (tabeldelete) => {
        if (tabeldelete) {
            SuperActivityService.deleteSuperActivity(tabeldelete).then((res) => {
                getSuperActivityList();
            }).catch((err) => {
            });
        }
    };
    const getActivityTabelList = () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetail"));
        ActivityTabelService.getAllActivityTabel(userDetails.schooleId).then((res) => {
            setSuperActivityList(res);
        }).catch((err) => {
           
        });
    }

    const formik = useFormik({
        initialValues: tabel,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const userDetails = JSON.parse(localStorage.getItem("userDetail"));
            values.schooleId = userDetails.schooleId;
            if (tabel._id) {
                SuperActivityService.upadeSuperActivity(values).then((res) => {
                    handleClose();
                    getSuperActivityList();
                    resetForm()
                    alert("Super Activity Updated Successfully.");
                }).catch((err) => {
                });
            }
            else {
                SuperActivityService.creteSuperActivity(values).then((res) => {
                    getSuperActivityList();
                    resetForm();
                    handleClose();
                    alert("Super Activity Added Successfully.");
                    // props.history.push('/app/vendor');
                })
                    .catch((err) => {
                        
                        alert(err.response.data.message)
                    })
            }

        },
    });
    return (
        <>
            <PageTitle title="Area Of Work" button={<Button
                variant="contained" onClick={() => handleOpen(true)}
                size="medium"
                color="secondary" style={{ backgroundColor: '#30875b' }}> Add Area Of Work
            </Button>} />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    {tableHeaders.map(key => (
                                        <StyledTableCell key={key}>{key}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {superActivityList.map((tabel) => (
                                    <TableRow key={tabel._id}>
                                       
{/* <TableCell className="pl-3 fw-normal" >{activity.classId ? activity.classId.className : ''}</TableCell> */}
<TableCell className="pl-3 fw-normal" >{tabel.classId ? tabel.classId.className:'' }</TableCell>

                                        <TableCell className="pl-3 fw-normal" >{tabel.superActivityName}</TableCell>
                                        <TableCell>
                                            <EditIcon style={{ cursor: 'pointer' }} onClick={() => editActivityTabel(tabel)} >
                                            </EditIcon >
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteActivityTabel(tabel)} />
                                        </TableCell>
        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Widget>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Super Activity</DialogTitle>
                <form onSubmit={formik.handleSubmit} >
                    <DialogContent style= {{ width: 308 }}>
                    <FormControl className={classes.formControl} fullWidth="true"
                                        >
                                            <InputLabel id="demo-simple-select-label">Select Class</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="classId"
                                                label="selectClass"
                                                value={formik.values.classId}
                                                onChange={formik.handleChange}
                                                error={formik.touched.classId && Boolean(formik.errors.classId)}
                                                helperText={formik.touched.classId && formik.errors.classId}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>

                                                {addClassList.map(({ _id, className }) => (
                                    <MenuItem key={_id} value={_id}>{className}
                                        
                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                        <TextField
                            InputProps={{ style: { width: 258 } }}
                            autoFocus
                            margin="dense"
                            id="superActivityName"
                            name="superActivityName"
                            label="Area Of Work"
                            type="text"
                            variant="standard"
                            value={formik.values.superActivityName}
                            onChange={formik.handleChange}
                            error={formik.touched.superActivityName && Boolean(formik.errors.superActivityName)}
                            helperText={formik.touched.superActivityName && formik.errors.superActivityName}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}


