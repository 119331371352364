import requests from './httpService';
const MovableService = {
  getAllMovable() {
    return requests.get(`/movable/list`);
  },
  creteMovable(body){
    return requests.post('/movable/add',body); 
  },
  deleteMovable(body){
    return requests.delete(`/movable/${body._id}`); 
  },
  findMovableList(body){
    return requests.post(`/movable/find`,body); 
  },
  upadeMovable(body) {
    return requests.patch(`/movable/update/${body._id}`,body); 
  },
  uploadImage(body){
    return requests.post('/sound/data/upload',body); 
  },
};
export default MovableService;
